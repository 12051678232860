
  import Vue, {PropType} from "vue";
  import {ChallengeType} from "@/utils/challenges";
  import {MODAL_IDS} from "@/utils/modal";
  import {IMAGES_SEGMENTS} from "@/utils/images";

  export default Vue.extend({
    name: "pwa-challenge-upload-modal",
    props: {
      challenge: {
        type: Object as PropType<ChallengeType>,
        required: true,
      },
      onUploadChallengeImageEvidenceClick: {
        type: Function as PropType<() => void>,
      },
    },
    computed: {
      modalId(): string {
        return MODAL_IDS.CHALLENGE_UPLOAD_MODAL;
      },
    },
    data() {
      return {
        imagesSegments: IMAGES_SEGMENTS,
      };
    },
    methods: {
      goToChallengeUrl(): void {
        if (this.challenge.url) {
          if (this.challenge.url.charAt(0) === '/') {
            this.routerPathPush(this.challenge.url);
          } else {
            this.openLink(this.challenge.url);
          }
        }else{
          if(this.challenge.game_id){
            // TODO
            // const deepLink = this.challenge.deeplink;
            // const packageName = this.challenge.package_name;
            // const iosUrl = this.challenge.ios_url;
            // const androidUrl = this.challenge.android_url;
          }
        }

        this.close()
      },
      onUploadChallengeImageEvidenceButtonClick() {
        if (this.onUploadChallengeImageEvidenceClick) {
          this.onUploadChallengeImageEvidenceClick();
        }

        this.close();
      },
      close() : void {
        this.hideModalById(this.modalId);
      },
    }
  });
