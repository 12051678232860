
  import Vue, {PropType} from "vue";
  import {CHALLENGES_STATUS_ID, ChallengeType} from "@/utils/challenges";
  import {IMAGES_SEGMENTS } from "@/utils/images";
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import PwaChallengeUploadModal from "@/components/modals/PwaChallengeUploadModal.vue";
  import {UserActionTypes} from "@/store/modules/user/actions";
  import {showInterstitial, showRewarded} from "@/services/ads";
  import {MODAL_IDS} from "@/utils/modal";
  import PwaInputFile from "@/components/PwaInputFile.vue";

  export default Vue.extend({
    name: "pwa-challenge-row",
    components: {
      PwaPrice,
      PwaInputFile,
    },
    data() {
      return {
        imagesSegments: IMAGES_SEGMENTS,
        challengesStatusId: CHALLENGES_STATUS_ID,
        numVideosWatched: 1,
      };
    },
    props: {
      challenge: {
        type: Object as PropType<ChallengeType>,
        required: true,
      },
    },
    computed: {
      challengeButtonText(): string {
        let labelId = '688';
        let params = {};

        if(this.challenge.textButtonLabelId){
          labelId = this.challenge.textButtonLabelId;
        }else if(this.challenge.challenge_status === CHALLENGES_STATUS_ID.APPROVED){
          labelId = '689';
        }else if(this.isWatchVideoChallenge){
          labelId = '690';
          params = {
            num: this.numVideosWatched,
            total: this.challenge.num_evidences,
          };
        }

        return this.$t(labelId, params);
      },
      isWatchVideoChallenge(): boolean {
        return this.challenge.url === '/video-ads';
      },
      rowClass(): Record<string, boolean> {
        return {
          started: this.challenge.challenge_status === this.challengesStatusId.STARTED,
          approved: this.challenge.challenge_status === this.challengesStatusId.APPROVED,
          rejected: this.challenge.challenge_status === this.challengesStatusId.REJECTED,
          finished: this.challenge.challenge_status === this.challengesStatusId.FINISHED,
        };
      },
    },
    methods: {
      onChallengeClick() {
        const {STARTED, FINISHED, APPROVED} = CHALLENGES_STATUS_ID;
        if(this.challenge.target_page != null){
          this.routerPathPush(this.challenge.target_page);
        }else if(this.isWatchVideoChallenge){
          if (this.challenge.challenge_status !== FINISHED) {
            this.onWatchVideoClick();
          }
        }else{
          if (this.challenge.challenge_status === APPROVED) {
            this.onApplyChallengeRewardClick();
          } else if (this.challenge.challenge_status !== STARTED && this.challenge.challenge_status !== FINISHED) {
            const message = this.$createElement(PwaChallengeUploadModal, {
              props: {
                challenge: this.challenge,
                onUploadChallengeImageEvidenceClick: () => (this.$refs.imageInput as any).chooseFile(),
              },
            });
            this.showModal({
              id: MODAL_IDS.CHALLENGE_UPLOAD_MODAL,
              title: this.challenge.title,
              message,
              hideHeaderClose: false,
              headerClass: 'title-centered',
              contentClass: {'challenge-upload': true, 'is-mobile': this.isMobile},
              footerClass: 'buttons-hidden',
            });
          }
        }
      },
      onWatchVideoClick() {
        this.showLoader(true);
        let watchedVideo = false;
        const onWatch = () => {
          if (!watchedVideo) {
            watchedVideo = true;
            if(this.numVideosWatched >= this.challenge.num_evidences){
              this.applyChallengeReward();
            }else{
              this.numVideosWatched++;
              this.showLoader(false);
            }
          }
        };

        showRewarded(true).then(showRewardedSuccess => {
          if (showRewardedSuccess) {
            onWatch();
          } else {
            this.showToastError(this.$t('139'), this.$t('140'));
          }
        });
      },
      async applyChallengeReward() {
        try {
          const { data } = await this.$http.challenges.postChallengeReward(this.challenge.id);
          this.showLoader(false);
          if (data.data.success) {
            this.onApplyChallengeRewardSuccess();
          } else {
            this.showToastError(this.$t('139'), this.$t('140'));
          }
        } catch (e) {
          this.showLoader(false);
          this.showToastError(this.$t('139'), this.$t('140'));
        }
      },
      async onUploadChallengeImageEvidenceSelected(img: string) {
        try {
          this.showLoader(true);
          const { data } = await this.$http.challenges.postChallengeEvidence(this.challenge.id, img);
          const numEvidences: number = data.data.num_evidences || 0;
          this.showLoader(false);
          if (numEvidences >= this.challenge.num_evidences) {
            this.onUploadChallengeEvidenceSuccess();
          } else {
            this.onUploadChallengeEvidencesLeft(numEvidences);
          }
        } catch (e) {
          this.showLoader(false);
          this.showToastError(this.$t('139'), this.$t('140'));
        }
      },
      onUploadChallengeEvidenceSuccess() {
        this.showModal({
          title: this.challenge.title,
          message: this.$t('705'),
          hideCancel: true,
          noCloseOnEsc: true,
          noCloseOnBackdrop: true,
          onOkClick: () => showInterstitial().finally(() => this.$emit('uploadEvidenceSuccess')),
        });
      },
      onUploadChallengeEvidencesLeft(numEvidences: number) {
        this.showModal({
          title: this.challenge.title,
          message: this.$t('706', {current: numEvidences, total: this.challenge.num_evidences}),
          okTitle: this.$t('703'),
          onOkClick: () => (this.$refs.imageInput as any).chooseFile(),
        });
      },
      onApplyChallengeRewardSuccess() {
        this.$store.dispatch(UserActionTypes.UPDATE_USER_WALLET);
        this.showModal({
          title: this.$t('665'),
          message: this.$t('695'),
          hideCancel: true,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          onOkClick: () => this.$emit('uploadEvidenceSuccess'),
        });
      },
      onApplyChallengeRewardClick() {
        this.showLoader(true);
        let rewardDone = false;
        const onRewarded = () => {
          if (!rewardDone) {
            rewardDone = true;
            this.applyChallengeReward();
          }
        };

        showRewarded(true).then(showRewardedSuccess => {
          if (showRewardedSuccess) {
            onRewarded();
          } else {
            showInterstitial(true).then(showInterstitialSuccess => {
              if (showInterstitialSuccess) {
                onRewarded();
              }
            });
            // Fallback por si acaso tarda mucho
            setTimeout(() => {
              onRewarded();
            }, 5000);
          }
        });
      }
    },
  });
