
  import Vue from "vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";

  export default Vue.extend({
    name: "pwa-accordion",
    components: {
      PwaIcon,
    },
    data() {
      return {
        accordionOpen: true,
      }
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        default: 'expand-down',
        required: false,
      },
      iconColor: {
        type: String,
        default: 'primary-text-color',
        required: false,
      },
      defaultOpen: {
        type: Boolean,
        default: true,
        required: false,
      },
      collapsable: {
        type: Boolean,
        default: true,
        required: false,
      },
    },
    beforeMount(): void {
      this.accordionOpen = this.defaultOpen;
    },
    methods: {
      toggleAccordion() {
        if (this.collapsable) {
          this.accordionOpen = !this.accordionOpen;
        }
      },
    },
    watch: {
      defaultOpen() {
        if (this.accordionOpen !== this.defaultOpen) {
          this.accordionOpen = this.defaultOpen;
        }
      },
    },
  });
