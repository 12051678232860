
  import Vue, {PropType} from 'vue';
  import {getImage, IMAGE_MAX_SIZE, IMAGES_READ_ERRORS, IMAGES_TYPES_ACCEPTED, ImageType} from "@/utils/form";
  import {BFormFile} from "bootstrap-vue";

  export default Vue.extend({
    name: "pwa-input-file",
    data() {
      return {
        image: null as File,
      };
    },
    props: {
      onFileSelected: {
        type: Function as PropType<(image: ImageType) => void>,
        required: false,
      },
      filesTypesAccepted: {
        type: Array,
        required: false,
        default: null,
      },
    },
    computed: {
      typesAccepted(): string {
        if (this.filesTypesAccepted && this.filesTypesAccepted.length > 0) {
          return this.filesTypesAccepted.join(', ');
        }

        return IMAGES_TYPES_ACCEPTED.join(', ');
      },
    },
    methods: {
      chooseFile() {
        const imageInputComponent = this.$refs.imageInput as BFormFile;
        const imageInput = imageInputComponent.$el as HTMLInputElement;
        imageInput.click();
      },
    },
    watch: {
      image() {
        if (this.image) {
          getImage(this.image).then(imageData => {
            if (this.onFileSelected) {
              this.onFileSelected(imageData);
            }
          }).catch(error => {
            switch (error) {
              case IMAGES_READ_ERRORS.TYPE_NOT_ALLOWED:
                this.showToastError(this.$t('139'), this.$t('154'));
                break;
              case IMAGES_READ_ERRORS.SIZE_NOT_ALLOWED:
                this.showToastError(this.$t('139'), this.$t('155', {size: IMAGE_MAX_SIZE}));
                break;
              default:
                this.showToastError(this.$t('139'), this.$t('140'));
                break;
            }
          }).finally(() => {
            const imageInputComponent = this.$refs.imageInput as BFormFile;
            imageInputComponent.reset();
          });
        }
      },
    }
  });
