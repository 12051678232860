export const CHALLENGES_STATUS_ID = {
  STARTED: 1,
  FINISHED: 2,
  REJECTED: 3,
  APPROVED: 4,
};

export const CHALLENGES_PERIOD_ID = {
  PROMOTED: 0,
  DAILY: 1,
  WEEKLY: 2,
  ONCE: 3,
};

export const CHALLENGES_REWARDS_TYPES = {
  NORMAL: 'normal',
  PROMO: 'promo',
};

export type ChallengeType = {
  id: number;
  challenge_status: number;
  company_logo: string;
  description: string;
  icon: string;
  num_evidences: number;
  reward_amount: number;
  url?: string;
  reward_image: string;
  reward_name: string;
  reward_type: string;
  title: string;
  target_page?: string;
  textButtonLabelId?: string;
  game_id?: number;
  deeplink?: string;
  package_name?: string;
  ios_url?: string;
  android_url?: string;
};
