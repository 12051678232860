
  import Vue from "vue";
  import {CHALLENGES_PERIOD_ID, ChallengeType} from "@/utils/challenges";
  import {ROUTES} from "@/router/routes";
  import PwaAccordion from "@/components/common/PwaAccordion.vue";
  import PwaChallengeRow from "@/components/PwaChallengeRow.vue";
  import { BreadcrumbItems, BreadcrumbItemType } from '@/utils/breadcrumb';
  import pageMixin from '@/mixins/page';

  type ChallengePeriodsType = {
    id: number;
    title: string;
    completed: boolean;
    children: ChallengeType[];
  };

  export default Vue.extend({
    name: "Challenges",
    mixins: [pageMixin],
    components: {
      PwaAccordion,
      PwaChallengeRow,
      PwaWebAdHalfPageBanner: () => import('@/components/ads/PwaWebAdHalfPageBanner.vue'),
      PwaWebAdLeaderboardBanner: () => import('@/components/ads/PwaWebAdLeaderboardBanner.vue'),
      PwaWebAdLargeMobileBanner: () => import('@/components/ads/PwaWebAdLargeMobileBanner.vue'),
      PwaWebAdInlineRectangleBanner: () => import('@/components/ads/PwaWebAdInlineRectangleBanner.vue'),
    },
    data() {
      return {
        challenges: [] as ChallengePeriodsType[],
        breadcrumb: [{labelId: BreadcrumbItems.challenges.labelId}] as BreadcrumbItemType[],
      };
    },
    mounted(): void {
      this.getChallengesPageData();
    },
    methods: {
      async getChallengesPageData() {
        try {
          this.showLoader(true);
          const {data}: any = await this.$http.page.getChallengesPage();
          const challenges: ChallengePeriodsType[] = data.data.challenges || [];
          const dailyChallenges = challenges.find(({ id }) => id === CHALLENGES_PERIOD_ID.DAILY);
          if (dailyChallenges) {
            const rouletteChallenge: ChallengeType = {
              id: -1,
              challenge_status: null,
              company_logo: '',
              description: '',
              icon: 'roulette.png',
              num_evidences: 0,
              reward_amount: null,
              reward_image: '',
              reward_name: '',
              reward_type: '',
              title: this.$t('686'),
              target_page: ROUTES.roulette.path,
              textButtonLabelId: '687'
            };

            dailyChallenges.children = [rouletteChallenge, ...dailyChallenges.children];
          }

          this.challenges = challenges;
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
    },
  });
